.custom-backdrop {
    --bs-backdrop-bg: #ebebeb57;/* Transparent white */
  }

  .image-container {
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  